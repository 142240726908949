// @flow

// import style from "./style.module.scss";

import React from "react";
import { Button as _Button } from "@material-ui/core";

/**
 * Button
 */
const Button = ({ type, onClick, children }: *) => (
    <_Button variant="contained" color={type} onClick={onClick}>
        {children}
    </_Button>
);

export default Button;
