// @flow

import React from "react";
import { InTheMaking } from "@components";

/**
 * Home Page
 */
const HomePage = () => <InTheMaking />;

export default HomePage;
