// @flow

import style from "./style.module.scss";

import React from "react";
import { Button } from "@components";
import { Grid } from "@material-ui/core";

import logo from "../../images/logo.svg";

/**
 * InTheMaking
 */
const InTheMaking = () => (
    <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        className={style.container}
    >
        <img
            src={logo}
            alt="Logo of Bouwwerken Sam"
            width="500px"
            height="500px"
        />
        <Button
            type={"primary"}
            onClick={() => window.open("mailto: sam.pauwels@telenet.be")}
        >
            Get in touch
        </Button>
    </Grid>
);

export default InTheMaking;
